import React, { useEffect, useRef } from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";
import CustomResults from "./custom_search_ui/CustomResults";
import CustomResult from "./custom_search_ui/CustomResult";
import CustomAppSearchAPIConnector from "./custom_search_ui/CustomAppSearchAPIConnector";
import "./style.css"
import CustomSearchBox from "./custom_search_ui/CustomSearchBox";
import CustomLayout from "./custom_search_ui/CustomLayout";

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new CustomAppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: false
};


export default function App() {



  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <CustomLayout
                  header={wasSearched && <CustomSearchBox autocompleteSuggestions={true}  />}
                  sideContent={
                    <div >
                      {wasSearched && (
                        <Sorting
                          className="custom-hide"
                          label={"Sort by"}
                          sortOptions={buildSortOptionsFromConfig()}
                        />
                      )}
                      {getFacetFields().map(field => (
                        <Facet className= "custom-hide" key={field} field={field} label={field} />
                      ))}
                    </div>
                  }
                  bodyContent={
                    <React.Fragment>
                      {
                        wasSearched && <CustomResults
                          titleField={getConfig().titleField}
                          thumbnailField={getConfig().thumbnailField}
                          shouldTrackClickThrough={true}
                          resultView={CustomResult}
                        />
                      }
                      {!wasSearched && <CustomSearchBox autocompleteSuggestions={true} />}
                      </React.Fragment>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage className="custom-hide" />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}